import React, { useEffect, useRef, useState } from "react"

import { Subscribe } from "unstated"
import RPContainer from "../../store"
import RenewalsContainer from "../../stores/renewals"
import RockPointsContainer from "../../stores/rockpoints"

import PaymentInfoSection from "../../components/PaymentInfoSection"

import RenewalsList from "./components/_RenewalsList"
import Layout from "./components/_Layout"

import loadingSpinner from "../../imgs/loading-spinner.svg"
import debounce from "../../utils/debounce"
import api from "../../utils/api"

function Billing() {
  const [invalidationCalled, setInvalidationCalled] = useState(false)
  const mountedRef = useRef(true)

  const invalidatePendingSubscription = debounce(async () => {
    try {
      await api.get(
        `/subscriptions/invalidate?timestamp=${new Date().getTime()}`
      )

      if (mountedRef.current) {
        setInvalidationCalled(true)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Couldnot invalidate subscriptions")
      setInvalidationCalled(true)
    }
  })
  useEffect(() => {
    invalidatePendingSubscription()
    return () => {
      invalidatePendingSubscription.cancel()
      mountedRef.current = false
    }
  }, [invalidatePendingSubscription])

  return (
    <Layout
      name="Billing & Credit"
      title="Manage billing information and view orders"
    >
      {!invalidationCalled && (
        <div style={{ textAlign: "center" }}>
          <img src={loadingSpinner} alt="spinner" />
        </div>
      )}

      {invalidationCalled && (
        <Subscribe to={[RPContainer, RenewalsContainer, RockPointsContainer]}>
          {(rpStore, renewalsStore, pointsStore) => (
            <React.Fragment>
              <PaymentInfoSection
                manageSubsLink={rpStore.state.manageSubsLink}
                fetchSubsLink={rpStore.fetchSubsLink}
                removeCreditCard={rpStore.removeCreditCard}
                rpStore={pointsStore}
                fetchRenewals={renewalsStore.fetchRenewals}
              />
              <RenewalsList
                fetchRenewals={renewalsStore.fetchRenewals}
                {...renewalsStore.state}
              />
            </React.Fragment>
          )}
        </Subscribe>
      )}
    </Layout>
  )
}

export default Billing
