export const formatDatetoEST = (date, withTime = false) => {
  try {
    let d = new Date(date)
    let dateOptions = {
      timeZone: "America/New_York",
      year: "numeric",
      month: "long",
      day: "numeric",
      ...(withTime && {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      }),
    }
    let formattedDate = d.toLocaleDateString("en-US", dateOptions)
    let timeZoneOptions = {
      timeZone: "America/New_York",
      timeZoneName: "short",
    }
    let formattedTimeZone = new Intl.DateTimeFormat("en-US", timeZoneOptions)
      .format(d)
      .split(" ")
      .pop()

    let estDate = `${formattedDate} ${formattedTimeZone}`
    return estDate
  } catch (err) {
    return ""
  }
}
