import React, { useEffect } from "react"
import { get } from "lodash"
// import moment from "moment"
import styled from "styled-components"
import ReactTooltip from "react-tooltip"

import SectionHeader from "./_SectionHeader"
import Table from "./_Table"
import { formatDatetoEST } from "../../../utils/dateformat"

import { getSubscriptionFeature as getFeature } from "../../../store"

const Container = styled.div`
  margin-bottom: 60px;

  .green,
  .gray {
    font-weight: 700;
  }

  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(3),
  td:nth-child(3) {
    @media screen and (max-width: 960px) {
      display: none;
    }
  }

  .mobile-only {
    @media screen and (min-width: 961px) {
      display: none;
    }
  }
`
const formatCurr = x => Number.parseFloat(x).toFixed(2)

const SubRow = props => {
  const proxy = getFeature(props, "Proxy Type")

  return (
    <tr>
      <td>
        {get(proxy, "servicesOffered", "no service found").split(",")[0]} —{" "}
        {get(proxy, "title", "no title found")}
        <br />
        <span className="small">
          Order#{get(props, "orderNumber", "not found")}
        </span>
        <br className="mobile-only" />
        <span className="small mobile-only">
          {formatDatetoEST(get(props, "autoRenewAt"), true)}
        </span>
      </td>
      <td>{formatDatetoEST(get(props, "autoRenewAt"), true)}</td>
      <td align="right" className="green">
        ${props.expectedCredit}
      </td>
      <td align="right">
        {props.expectedCredit > 0 ? (
          <span>
            <span className="gray">${props.billAmount}</span> -{" "}
            <span className="green">${props.expectedCredit}</span> =
            <strong>
              {" "}
              ${formatCurr(props.billAmount - props.expectedCredit)}
            </strong>
          </span>
        ) : (
          <strong>${formatCurr(props.billAmount)}</strong>
        )}
      </td>
    </tr>
  )
}

export default function RenewalsList({ renewals, fetchRenewals }) {
  useEffect(() => {
    fetchRenewals()
  }, [])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [renewals])

  return (
    <Container>
      <SectionHeader title="Upcoming Renewals" />
      {renewals.length === 0 ? (
        <p>You have no upcoming subscription renewals.</p>
      ) : (
        <Table>
          <thead>
            <tr>
              <th className="strong left">Product</th>
              <th className="strong left" data-tip="Upcoming Renewal date">
                Date
              </th>
              <th
                className="strong right"
                data-tip="Expected Credit to be applied"
              >
                Credit
              </th>
              <th
                className="strong right"
                data-tip="Expected Subscription Card Payment"
              >
                You Pay
              </th>
            </tr>
          </thead>
          <tbody>
            {renewals.map((elem, idx) => {
              return <SubRow key={idx} {...elem} />
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="4">
                This “Upcoming Renewals” list shows only your next 10 renewals.
              </td>
            </tr>
          </tfoot>
        </Table>
      )}
    </Container>
  )
}
