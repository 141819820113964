import React, { useEffect } from "react"
import styled from "styled-components"

import { get } from "lodash"

import SectionHeader from "./_SectionHeader"
import Table from "./_Table"

const Container = styled.div`
  margin-bottom: 30px;

  .info h3 {
    color: #7587f5;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: 10px;

    padding-left: 20px;
  }

  .highlight {
    font-size: 16px;
    font-weight: 700;

    &:first-child {
      left: 0;
      position: absolute;
    }
  }

  .info {
    margin-bottom: 40px;
  }

  h3 {
    font-size: 18px;
    margin: 0;
  }

  .sep {
    margin: 18px 0;
    border: none;
    border-bottom: 1px solid #e0e3e5;
  }

  .green {
    font-weight: 700;
  }
`

export const RockPointsDisclaimer =
  "As of 7/17/2023 our Rockpoints program has been discontinued. We are glad to honor any Rockpoints accrued before or on this date. Please contact our support team to get your account credited."

const Transaction = ({ type, points, transaction, rate, notes }) => {
  const isCredit = type === "Credit"

  return (
    <tr>
      <td>
        {isCredit ? "Earned" : "Redeemed"}
        <br />
        {isCredit ? (
          <span className="small">
            Order #{get(transaction, "subscription.orderNumber")}
            <br />
            Transaction #{get(transaction, "transactionNumber")}
          </span>
        ) : (
          <span className="small">
            ${points / rate} subcription bill credit.
          </span>
        )}
      </td>
      <td>{notes}</td>
      <td align="right">
        {!isCredit && "-"}
        {points}
      </td>
    </tr>
  )
}

const RockPoints = ({ rpStore }) => {
  useEffect(() => {
    rpStore.fetchData()
  }, [])

  return (
    <Container>
      <div className="info">
        <p>{RockPointsDisclaimer}</p>
        {/* <h3>
          <span className="highlight">1</span>
          <span className="highlight">Earn </span>RockPoints each time your
          subscription renews.
        </h3>
        <h3>
          <span className="highlight">2</span>
          <span className="highlight">Buy </span>RockPoints using many payment
          methods.
        </h3>
        <h3>
          <span className="highlight">3</span>
          <span className="highlight">Save </span>money on upcoming subscription
          renewals with RockPoints!
        </h3> */}
      </div>

      <SectionHeader title="Your RockPoints">
        <h3 className="green">{rpStore.rockPoints}</h3>
      </SectionHeader>

      <p>
        <strong>Earn and Redeem History</strong>
      </p>

      <Table>
        <thead>
          <tr>
            <th className="strong left">item</th>
            <th className="strong left">notes</th>
            <th className="strong right">amount</th>
          </tr>
        </thead>
        <tbody>
          {rpStore.state.history.map(t => (
            <Transaction {...t} key={t.id} rate={rpStore.rockPointsRate} />
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default RockPoints
