import { Container } from "unstated"

import api from "../utils/api"
import deserialize from "../utils/deserialize"
import debounce from "../utils/debounce"

class RenewalsStore extends Container {
  constructor(props) {
    super(props)

    this.fetchRenewals = debounce(this.fetchRenewals)
  }

  meta = {
    fetched: false,
  }

  state = {
    renewals: [],
  }

  fetchRenewals = async skipCheck => {
    if (!skipCheck && this.meta.fetched) return

    this.meta.fetched = true

    try {
      const res = await api.get("/subscriptions/upcoming")
      const renewals = await deserialize(res.data)
      await this.setState({ renewals })
      return renewals
    } catch (e) {
      this.meta.fetched = false
      console.error(e)
    }
  }
}

export default RenewalsStore
